* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.my-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.my-title h2 {
  color: #009760;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 600;
}
.title-underLine {
  width: 130px;
  height: 5px;
  background: linear-gradient(to right, #212cff 0%, #787fff 50%, #212cff 100%);
  border-radius: 10px;
}
